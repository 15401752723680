import React from "react";
import Products from "./Products/Products";
import ganesh from "../../../Assets/Best/ganesh.jpg";
import diya from "../../../Assets/Best/diya.jpg";
import mitti from "../../../Assets/Best/mitti.png";
import "./Best.css";
function Best() {
  return (
    <>
      <div className="bestContainer" id="best">
        <h1>Best Sellers</h1>
        <div className="products">
          <Products img={ganesh} title={"Ganesh Maatli"} />
          <Products img={diya} title={"Diya Decoration"} />
          <Products img={mitti} title={"Mitti Kalash"} />
        </div>
      </div>
    </>
  );
}

export default Best;
