import React from "react";
import "./Products.css";

function Products({ img, title }) {
  return (
    <>
      <div className="box">
        <div className="boximg">

        <img src={img} alt="img" />
        </div>
        <div className="title">{title}</div>
      </div>
    </>
  );
}

export default Products;
