import React from "react";
import logo from "../../Assets/logo.png";
import { Container, Nav, Navbar } from "react-bootstrap";
import { RxHamburgerMenu } from "react-icons/rx";
import "./Navbars.css";
function Navbars() {
  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      sticky="top"
      style={{ background: "#fff", margin: "0 10px" }}
    >
      <Container>
        <Navbar.Brand href="#">
          <img src={logo} alt="logo" />
        </Navbar.Brand>
        <Navbar.Toggle>
          <RxHamburgerMenu color="#F7ACBE" size={"1.7rem"} />
        </Navbar.Toggle>
        <Navbar.Collapse>
          <Nav className="me-auto">
            <Nav.Link href="#about">About</Nav.Link>
            <Nav.Link href="#best">Best Seller</Nav.Link>
            <Nav.Link href="#bulk">Bulk Order</Nav.Link>
            <Nav.Link href="#contact">Contact Us</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Navbars;
