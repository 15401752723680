import React from "react";
import timer from "../../../Assets/timer.jpg";
import CountdownTimer from "./CountDown";
function Timer() {
  return (
    <>
      <div className="timerContainer">
        <div className="timerImg">
          <img src={timer} alt="timer" />
        </div>
        <div className="timerContent">
          <h1>Tej celebration box - FLASH SALE</h1>
          <p>
            In today's fast-paced life, we are your solution to celebrate every
            Indian festival without stress with your near & dear
          </p>
          <CountdownTimer />
        </div>
      </div>
    </>
  );
}

export default Timer;
