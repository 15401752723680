import React from "react";
import Slider from "react-slick";
import "./Home.css";
import banner1 from "../../Assets/Banner/banner1.png";
import banner2 from "../../Assets/Banner/banner2.png";
import About from "./About/About";
import Best from "./Best/Best";
import Timer from "./Timer/Timer";
function Home() {
  return (
    <>
      <Slider arrows={false} dots slidesToShow={1} slidesToScroll={1} autoplay>
        <div>
          <img src={banner1} alt="bannerimage" />
        </div>
        <div>
          <img src={banner2} alt="bannerimage" />
        </div>
      </Slider>
      <About />
      <Best />
      <Timer />
    </>
  );
}

export default Home;
