import React from "react";
import "./About.css";
import about from "../../../Assets/About/about.jpg";
function About() {
  return (
    <>
      <div className="aboutContainer" id="about">
        <div className="aboutContent">
          <p>
            We specialize in creating exquisite handmade decorative items.Our
            passion lies in crafting unique pieces that add charm and beauty to
            any space. With a focus on traditional Indian art forms and an eye
            for detail, our collection includes a range of stunning items such
            as decorative diyas, paper kandils, Ganesh maatli for marriage,
            Mitti Kalash for Shobha Yatra, and various other decorative pieces.
          </p>
          <a href="*">Shop Now</a>
        </div>
        <div className="aboutImg">
          <img src={about} alt="about" />
        </div>
      </div>
    </>
  );
}

export default About;
