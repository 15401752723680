import React, { useState, useEffect } from "react";
import "./Timer.css";
const CountdownTimer = () => {
  const [timeRemaining, setTimeRemaining] = useState(getTimeRemaining());

  function getTimeRemaining() {
    const now = new Date();
    const midnight = new Date(now);
    midnight.setHours(24, 0, 0, 0); // Set to midnight
    return midnight.getTime() - now.getTime();
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeRemaining(getTimeRemaining());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const formatTime = (milliseconds) => {
    const seconds = Math.floor(milliseconds / 1000) % 60;
    const minutes = Math.floor(milliseconds / 1000 / 60) % 60;
    const hours = Math.floor(milliseconds / 1000 / 60 / 60) % 24;
    const days = Math.floor(milliseconds / 1000 / 60 / 60 / 24);

    return (
      <>
        <div className="timerValue">
          <span>{days}</span>
          <p>Days</p>
        </div>
        <div className="timerValue">
          <span>{hours.toString().padStart(2, "0")}</span>
          <p>Hours</p>
        </div>
        <div className="timerValue">
          <span>{minutes.toString().padStart(2, "0")}</span>
          <p>Minutes</p>
        </div>
        <div className="timerValue">
          <span>{seconds.toString().padStart(2, "0")}</span>
          <p>Seconds</p>
        </div>
      </>
    );
  };

  return (
    <>
      <div className="countdown-container">{formatTime(timeRemaining)}</div>
    </>
  );
};

export default CountdownTimer;
