import Home from "./Components/Home/Home";
import Navbars from "./Components/Navbars/Navbars";
import Marquee from "react-fast-marquee";
import ScrollToTop from "react-scroll-to-top";
import { BsArrowUpCircleFill } from "react-icons/bs";
import "./App.css";

function App() {
  return (
    <div className="App">
      <Marquee
        style={{
          backgroundColor: "#F7ACBE",
          fontWeight: "600",
          fontSize: "larger",
        }}
      >
        Flash Sale! Upto 10% OFF
      </Marquee>
      <Navbars />
      <Home />
      <ScrollToTop
        smooth
        component={<BsArrowUpCircleFill size={"2rem"} color="#f05a7e" />}
      />
    </div>
  );
}

export default App;
